import { useParams } from "react-router-dom";
import { useEffect, useReducer, useState } from "react";
import { Dossier } from "../../models/Dossier";
import { useDossier } from "../../hooks/useDossier";
import { get } from "http";
import Swal from "sweetalert2";
import logo from '../../assets/logo.png';

//Bootstrap
import { Card, InputGroup, Button, Row, Col, ButtonGroup, FormCheck, ToggleButton, Spinner, FormControl } from "react-bootstrap";


function DQuestions() {
    /**
     * Hooks
     */
    const routeParams = useParams();
    const { dToken } = routeParams;
    const { getDossierByToken, putReponseByToken, addDocumentByToken } = useDossier();

    /**
     * States
     */
    const [dossier, setDossier] = useState<Dossier | null>(null);
    const [reponses, setReponses] = useState<any[]>([]);
    const [loadingDossier, setLoadingDossier] = useState<boolean>(true);
    const [loadingReponses, setLoadingReponses] = useState<boolean>(false);
    const [loadingDocuments, setLoadingDocuments] = useState<boolean>(false);
    const [uploadedFiles, setUploadedFiles] = useState<any[]>([]);
    
    /**
     * useEffect
     */
    useEffect(() => {
        if (dToken) {
            getDossierByToken(dToken)
                .then((data) => {
                    setDossier(data)
                    setLoadingDossier(false);
                })
                .catch((error) => {
                    Swal.fire({
                        title: 'Erreur',
                        text: error.detail,
                        icon: 'error'
                    });
                });
        }
    }, [dToken]);

    /**
     * Handlers
     */
    const handleReponse = (reponseId: number, value: string) => {
        const newReponses = [...dossier.reponses];
        newReponses[newReponses.findIndex((reponse) => reponse.id === reponseId)].reponse = value;
        setDossier({ ...dossier, reponses: newReponses });
    }

    const handleSendReponses = () => {
        setLoadingReponses(true);
        
        //Pour chaque question, on associé une réponse
        const reponsesToSend = dossier?.reponses.map((reponse, index) => ({
            id: reponse.id,
            reponse: reponse.reponse
        }));

        putReponseByToken(dToken, reponsesToSend)
            .then((data) => {
                Swal.fire({
                    title: 'Merci',
                    text: data.message,
                    icon: data.result,
                    showConfirmButton: true,
                    confirmButtonText: 'Créez un compte pour suivre votre dossier',
                })
                    .then((result) => {
                        if (result.isConfirmed) {
                            window.location.href = 'https://pro.liaisons-habitat.fr/register';
                        }
                    });
                setLoadingReponses(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Erreur',
                    text: error.detail,
                    icon: 'error'
                });
                setLoadingReponses(false);
            });
    }

    const handleUploadFile = (file, documentDemandeId) => {
        setLoadingDocuments(true);
        addDocumentByToken(dToken, file, documentDemandeId)
            .then((data) => {
                setUploadedFiles([...uploadedFiles, documentDemandeId]);
                setLoadingDocuments(false);
            })
            .catch((error) => {
                Swal.fire({
                    title: 'Erreur',
                    text: error.detail,
                    icon: 'error'
                });
                setLoadingDocuments(false);
            });
    }

    /**
     * Render
     */
    if (!dToken) return null;

    if (loadingDossier) return (
        <div className="pt-3 px-3 d-flex justify-content-center">
            <div style={{
                maxWidth: '600px',
                }}>
                <Row>
                    <Col className="mb-2 p-0 text-center">
                        <img src={logo} alt="logo" height="50" />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="text-center">
                        <Spinner className="align-self-center" style={{ marginBottom: '20px' }} animation="border" variant="primary" /><br />
                        Chargement du dossier en cours...
                    </Col>
                </Row>
            </div>
        </div>
    );

    return (
        <div className="pt-3 px-3 d-flex justify-content-center">
            {/* show a backdrop while loading document */}
            {loadingDocuments && (
                <div className="position-fixed w-100 h-100 d-flex justify-content-center align-items-center" style={{ backgroundColor: 'rgba(0,0,0,0.5)', top: 0, left: 0, zIndex: 1000 }}>
                    <Spinner animation="border" variant="primary" />
                </div>
            )}
            <div style={{
                maxWidth: '600px',
                }}>
                <Row>
                    <Col className="mb-2 p-0 text-center">
                        <img src={logo} alt="logo" height="50" />
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="text-center">
                        Nous avons besoin d'informations avant de transmettre votre dossier à nos clients.
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="text-center" md={12}>
                        <strong>Bien concerné</strong>
                    </Col>
                    <Col className="text-center" md={12}>
                        {dossier?.adresse}, {dossier?.codePostal} {dossier?.commune}
                    </Col>
                </Row>
                <Row className="mt-5">
                    <Col className="text-center" md={12}>
                        <strong>Informations nécessaires</strong>
                    </Col>
                </Row>
                {dossier?.reponses.map((reponse, index) => (
                    <Row className={'mt-3 '+(reponse.question?.questionMere==null?'':(dossier.reponses.filter((reponseMere) => reponseMere.question?.id == reponse.question?.questionMere.id)[0].reponse == 'oui'?'':'d-none'))}>
                        <Col className="d-flex" md={12}>
                            {reponse.question?reponse.question.label:reponse.questionOuverte}
                        </Col>
                        <Col className="d-flex flex-row-reverse mt-1" md={12}>
                            {reponse.question ? (
                                <ButtonGroup aria-label={reponse.question.label}>
                                    <ToggleButton
                                        id={`radio-${index}-1`}
                                        type="radio"
                                        variant="outline-primary"
                                        name={`radio-${index}`}
                                        onClick={() => handleReponse(reponse.id, "oui")}
                                        checked={reponse.reponse === "oui" ? true : false}
                                        value="oui"
                                    >
                                        Oui
                                    </ToggleButton>
                                    <ToggleButton
                                        id={`radio-${index}-2`}
                                        type="radio"
                                        variant="outline-primary"
                                        name={`radio-${index}`}
                                        onClick={() => handleReponse(reponse.id, "non")}
                                        checked={reponse.reponse === "non" ? true : false}
                                        value="non"
                                    >
                                        Non
                                    </ToggleButton>
                                </ButtonGroup>
                            ) : (
                                <FormControl
                                    as="textarea"
                                    aria-label="With textarea"
                                    placeholder="Votre réponse"
                                    value={reponse.reponse}
                                    onChange={(e) => handleReponse(reponse.id, e.target.value)}
                                />
                            )}
                        </Col>
                    </Row>
                ))}
                {dossier?.documentDemandes?.length > 0 && (
                    <>
                        <Row className="mt-5">
                            <Col className="text-center" md={12}>
                                <strong>Documents nécessaires</strong>
                            </Col>
                        </Row>
                        {dossier?.documentDemandes.map((documentDemande, index) => (
                            <Row className="mt-3">
                                <Col className="d-flex" md={12}>
                                    {documentDemande.type?.label}
                                </Col>
                                <Col className="d-flex flex-row-reverse mt-1" md={12}>
                                    <input 
                                        type="file"
                                        style={{
                                            display: 'none'
                                        }}
                                        onChange={(e) => handleUploadFile(e.target.files[0], documentDemande.type.id)}
                                        id={'document-'+index} />
                                    {uploadedFiles.includes(documentDemande.type.id) ? (
                                        <Button variant="success" disabled>
                                            Fichier envoyé
                                        </Button>
                                    ) : (
                                        <Button variant="outline-primary" onClick={() => document.getElementById('document-'+index).click()}>Choisir un fichier</Button>
                                    )}
                                </Col>
                            </Row>
                        ))}
                    </>
                )}
                <Row className="mt-5">
                    <Col className="text-center">
                        <Button variant="primary" onClick={handleSendReponses} disabled={loadingReponses || dossier?.reponses.filter((reponse) => reponse.reponse == null && reponse.question?.questionMere == null).length > 0 || dossier?.documentDemandes?.filter((documentDemande) => documentDemande.mandatory && !uploadedFiles.includes(documentDemande.type.id)).length > 0}>
                            {loadingReponses ? (
                                <Spinner animation="border" size="sm" />
                            ) : (
                                <>
                                    Envoyer vos réponses
                                </>
                            )}
                        </Button>
                    </Col>
                </Row>
            </div>
        </div>
    );
}

export default DQuestions;
