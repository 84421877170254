//React
import React from 'react';
import { useEffect } from 'react';

//Leaflet
import { MapContainer, TileLayer, LayersControl, ZoomControl } from 'react-leaflet';

//Components
import MapAction from './MapAction';
import MapLoader from './MapLoader';
import MapCadastreLayer from './MapCadastreLayer';

//Bootstrap

//Hooks


function Map() {
    useEffect(() => {
        const L = require("leaflet");

        delete L.Icon.Default.prototype._getIconUrl;

        L.Icon.Default.mergeOptions({
            iconRetinaUrl: require("leaflet/dist/images/marker-icon-2x.png"),
            iconUrl: require("leaflet/dist/images/marker-icon.png"),
            shadowUrl: require("leaflet/dist/images/marker-shadow.png")
        });
    }, []);
    
    return (
        <MapContainer center={[49.42884551041268, 1.1268884745155792]} zoom={16} zoomControl={false} scrollWheelZoom={true} style={{position: 'absolute', height: "100%", width: "100%"}}>
            <LayersControl position="topright">
                <LayersControl.BaseLayer checked name="Sattelite">
                    <TileLayer
                        attribution='© Google Maps'
                        url="https://www.google.cn/maps/vt?lyrs=s@189&gl=cn&x={x}&y={y}&z={z}"
                        maxZoom={20}
                        subdomains="['mt0','mt1','mt2','mt3']"
                    />
                </LayersControl.BaseLayer>
                <LayersControl.BaseLayer name="Rue">
                    <TileLayer
                        attribution='&copy; <a href="https://www.openstreetmap.org/copyright">OpenStreetMap</a> contributors'
                        url="https://{s}.tile.openstreetmap.org/{z}/{x}/{y}.png"
                        maxZoom={20}
                    />
                </LayersControl.BaseLayer>
            </LayersControl>
            <ZoomControl position="topright"  />
            <MapAction />
            <MapLoader />
            <MapCadastreLayer />
        </MapContainer>
    );
}

export default Map;